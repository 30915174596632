<template>
   <header class="header d-flex align-item-center justify-content-center p-5 position-relative">
      <a href="index.html" class="text-center">
         <img class="h-100" :src="currentLogo" alt />
      </a>

      <!-- navbar for mobile -->
      <a v-show="isShowBackIcon" class="header-back__back ltr-type" @click="back()" ref="js_backbtn">
         <svg
         class="header-back__svg"
         xmlns="http://www.w3.org/2000/svg"
         width="13"
         height="24"
         viewBox="0 0 13 24"
         fill="#000"
         >
         <path
            d="M19.4,56.761l.38-.38a.646.646,0,0,0,0-.913L9.259,44.95,19.776,34.433a.646.646,0,0,0,0-.913l-.38-.38a.646.646,0,0,0-.913,0L7.129,44.494a.646.646,0,0,0,0,.913L18.483,56.761a.646.646,0,0,0,.913,0Z"
            transform="translate(-6.94 -32.951)"
         />
         </svg>
         <!-- <span class="pl-7">{{ $t("Back") }}</span> -->
      </a>
   </header>
</template>

<script>
export default {
   name: "PublicHeader",
   props: {
      msg: String
   },
   data() {
      return {
         isShowBackIcon: true
      }
   },
   methods: {
      formatNumber(number) {
         let numberFormatObject = new Intl.NumberFormat("en-US");
         return numberFormatObject.format(number);
      },
      toTitleCase(str) {
         return str.replace(/\w\S*/g, function(txt) {
         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
         });
      },
      back() {
         const id = localStorage.getItem("transactionId");
         const phoneNumber = localStorage.getItem("phone_number");
         if (this.$route.name == "passport-detail") {
            this.$router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
         } else if (this.$route.name == "order-passport") {
            this.$router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
         } else if (this.$route.name == "order-confirmation") {
            this.$router.push({ name: 'order-details', params: { transaction_id: id, phone_number: phoneNumber } });
         } else if (this.$route.name == "tour-order6") {
            this.$router.push({ name: 'order-confirmation', params: { transaction_id: id, phone_number: phoneNumber } });
         }
      }
   },
   computed: {},
   created() {
      if (this.$route.name == "order-details" || this.$route.name == "PaymentVerificationPublic" || this.$route.name == "404") {
         this.isShowBackIcon = false;
      } else {
         this.isShowBackIcon = true;
      }
   },
   mounted() {
      // if (
      //    this.$route.name == "passport-detail" ||
      //    this.$route.name == "order-passport" ||
      //    this.$route.name == "order-confirmation" ||
      //    this.$route.name == "tour-order6"
      // ) {
      //    this.$refs.js_backbtn.classList.remove("hide-item");
      // } else {
      //    this.$refs.js_backbtn.classList.add("hide-item");
      // }
      if (this.$i18n.locale == "en") {
         document.getElementsByTagName("body")[0].classList.remove("rtl-type");
         document.getElementsByTagName("body")[0].classList.add("ltr-type");
      } else {
         document.getElementsByTagName("body")[0].classList.remove("ltr-type");
         document.getElementsByTagName("body")[0].classList.add("rtl-type");
      }
   }
};
</script>
<style lang="scss">
   .header {
      height: 64px;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
   }

   .header-back__back {
      top: 50%;
      transform: translateY(-50%);
   }

</style>
