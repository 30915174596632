<template>
  <v-app>
    <PublicHeader />
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-app>
  <!-- <div class="page app">
    <div>
      <PublicHeader/>
      <div class="page__wrapper">
        <router-view></router-view>
      </div>
    </div>
  </div> -->
</template>

<script>
import PublicHeader from '../components/PublicHeader'
import { mapActions } from 'vuex'
export default {
  name: 'PublicContainer',
    components: {
      PublicHeader
    },
}
</script>

<style lang="scss" scoped>
.v-application {
  background-color: #f5f8fa;
  .page_bg_grey {
     background: #f5f8fa;
  }
}
</style>